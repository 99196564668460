<template>
  <panel
    :breadcrumbs="[]"
    :title="title"
  >
    <v-alert
      :value="announcement"
      color="green"
      icon="mdi-bullhorn"
    >
      <div v-html="announcement" />
    </v-alert>
    <v-alert
      :value="month !== monthOf"
      color="info"
      icon="info"
    >
      Previous month KPI does not reflect the actual monthly KPI that has been
      finalized because subscribers may have been terminated or ported out.
    </v-alert>
    <v-toolbar
      dense
      card
    >
      <v-menu
        v-model="menu"
        lazy
        close-on-content-click
        transition="scale-transition"
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="monthOf"
          prepend-icon="event"
          readonly
        />
        <v-date-picker
          v-model="monthOf"
          :max="maxDate"
          :allowed-dates="allowedMonths"
          min="2020-01"
          type="month"
          year-icon="date_range"
        />
      </v-menu>
      <v-spacer />
      <v-flex xs3>
        <v-text-field
          v-model="search"
          label="Search"
          clearable
        />
      </v-flex>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </v-toolbar>
    <v-card
      v-if="kpi2025 || !stopKpi && kpiAchievement"
      color="black"
    >
      <v-card-text style="height: 10px; position: relative">
        <v-btn
          absolute
          dark
          fab
          top
          right
          :ripple="false"
          large
          color="black"
        >
          <v-avatar
            size="50px"
            :color="kpiColor"
          >
            <span class="white--text subheader">{{ kpiAchievement }}</span>
          </v-avatar>
        </v-btn>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="items"
      expand
      hide-actions
      item-key="indicator"
    >
      <template #items="props">
        <tr @click="(props.item.indicator !== '(Payout + Black AI) last month') && (props.expanded = !props.expanded)">
          <td class="text-xs-left">
            {{ props.item.indicator }}
          </td>

          <!-- For KPI 2025 -->
          <template v-if="kpi2025">
            <td class="text-xs-center">
              {{ props.item.kpi01goal25 }}
            </td>
            <td class="text-xs-center">
              {{ props.item.kpi02goal25 }}
            </td>
            <td class="text-xs-center">
              {{ props.item.kpi03goal25 }}
            </td>
            <td class="text-xs-center">
              {{ props.item.kpi04goal25 }}
            </td>
            <td class="text-xs-center">
              {{ props.item.kpi05goal25 }}
            </td>
            <td class="text-xs-center">
              {{ props.item.kpi06goal25 }}
            </td>
            <td class="text-xs-center">
              {{ props.item.kpi07goal25 }}
            </td>
            <td class="text-xs-center primary">
              {{ props.item.achievement }}
            </td>
          </template>

          <!-- For KPI 2022 -->
          <template v-else-if="kpi2022 && !stopKpi">
            <template v-if="kpiSept2022">
              <td
                v-if="props.item.indicator === 'Activation'"
                class="text-xs-center"
                v-html="props.item.kpi01Agoal + '<br><i>or</i>'"
              />
              <td
                v-else
                class="text-xs-center"
                v-html="props.item.kpi01Agoal"
              />

              <td
                v-if="props.item.indicator === 'Activation'"
                class="text-xs-center"
                v-html="props.item.kpi01Bgoal + '<br><i>and</i>'"
              />
              <td
                v-else
                class="text-xs-center"
                v-html="props.item.kpi01Bgoal"
              />

              <td class="text-xs-center">
                {{ props.item.kpi01Cgoal }}
              </td>
            </template>
            <template v-else>
              <td class="text-xs-center">
                {{ props.item.kpi01goal }}
              </td>
            </template>

            <td
              v-if="props.item.indicator === 'Activation'"
              class="text-xs-center"
              v-html="props.item.kpi02goal + '<br><i>and</i>'"
            />
            <td
              v-else
              class="text-xs-center"
              v-html="props.item.kpi02goal"
            />

            <td class="text-xs-center">
              {{ props.item.kpi03goal }}
            </td>
            <td class="text-xs-center primary">
              {{ props.item.achievement }}
            </td>
          </template>

          <!-- For other KPI types or when not using KPI 2025 or 2022 -->
          <template v-else>
            <td class="text-xs-center">
              {{ props.item.goal }}
            </td>
            <td
              v-if="props.item.indicator !== 'Super Dealer Campaign'"
              class="text-xs-center primary"
            >
              {{ props.item.achievement }}
            </td>
            <td v-if="props.item.indicator === 'Super Dealer Campaign'" />
          </template>
        </tr>
      </template>
      <template #expand="props">
        <template v-if="props.item.indicator ==='Super Dealer Campaign'">
          <v-card>
            <v-card-text>
              <v-data-table
                :headers="props.item.headers"
                :items="campaign"
                expand
                hide-actions
                item-key="indicator"
              >
                <template #items="thisProps">
                  <tr @click="thisProps.expanded = !thisProps.expanded">
                    <td class="text-xs-left">
                      {{ thisProps.item.indicator }}
                    </td>
                    <td class="text-xs-center">
                      {{ thisProps.item.goal }}
                    </td>
                    <td class="text-xs-center primary">
                      {{ thisProps.item.achievement }}
                    </td>
                  </tr>
                </template>

                <template #expand="thisProps">
                  <v-card>
                    <v-card-text>
                      <v-data-table
                        :headers="thisProps.item.headers"
                        :items="thisProps.item.items"
                        class="elevation-1"
                        item-key="name"
                        :rows-per-page-items="[5,10,25,50]"
                        :custom-filter="customFilter"
                        :search="search"
                      >
                        <template #items="subProps">
                          <td class="text-xs-left">
                            {{ subProps.item.name }}
                          </td>
                          <td class="text-xs-center">
                            {{ subProps.item.value }}
                          </td>
                          <td
                            v-if="subProps.item.active"
                            class="text-xs-center"
                          >
                            <v-icon color="success">
                              mdi-check
                            </v-icon>
                          </td>
                          <td
                            v-else-if="subProps.item.amount"
                            class="text-xs-center"
                          >
                            {{ subProps.item.amount }}
                          </td>
                          <td
                            v-else
                            class="text-xs-center"
                          >
                            <v-icon color="error">
                              mdi-close
                            </v-icon>
                          </td>
                          <td
                            v-if="subProps.item.qualified"
                            class="text-xs-center"
                          >
                            <v-icon
                              v-if="
                                subProps.item.note && subProps.item.note === 'WAWA18'
                              "
                              color="teal"
                            >
                              mdi-fraction-one-half
                            </v-icon>
                            <v-icon
                              v-else
                              color="success"
                            >
                              mdi-check
                            </v-icon>
                          </td>
                          <td
                            v-else
                            class="text-xs-center"
                          >
                            <v-icon color="error">
                              mdi-close
                            </v-icon>
                          </td>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </template>
        <template v-else>
          <v-card>
            <v-card-text>
              <v-data-table
                :headers="props.item.headers"
                :items="props.item.items"
                :rows-per-page-items="[5,10,25,50]"
                class="elevation-1"
                :custom-filter="customFilter"
                :search="search"
              >
                <template #items="thisProps">
                  <td class="text-xs-left">
                    {{ thisProps.item.name }}
                  </td>
                  <td class="text-xs-center">
                    {{ thisProps.item.value }}
                  </td>
                  <td
                    v-if="thisProps.item.active"
                    class="text-xs-center"
                  >
                    <v-icon color="success">
                      mdi-check
                    </v-icon>
                  </td>
                  <td
                    v-else-if="thisProps.item.amount"
                    class="text-xs-center"
                  >
                    {{ thisProps.item.amount }}
                  </td>
                  <td
                    v-else
                    class="text-xs-center"
                  >
                    <v-icon color="error">
                      mdi-close
                    </v-icon>
                  </td>
                  <template v-if="kpiAct2024">
                    <template
                      v-if="props.item.indicator === 'Activation'"
                    />
                    <template
                      v-else
                    >
                      <td
                        v-if="thisProps.item.qualified"
                        class="text-xs-center"
                      >
                        <v-icon
                          v-if="
                            thisProps.item.note && thisProps.item.note === 'WAWA18'
                          "
                          color="teal"
                        >
                          mdi-fraction-one-half
                        </v-icon>
                        <v-icon
                          v-else-if="thisProps.item.qualified === '1' || thisProps.item.qualified === true "
                          color="success"
                        >
                          mdi-check
                        </v-icon>
                        <v-icon
                          v-else-if="thisProps.item.qualified === '0' "
                          color="error"
                        >
                          mdi-close
                        </v-icon>
                      </td>
                    </template>
                  </template>
                  <template v-else>
                    <td
                      v-if="thisProps.item.qualified"
                      class="text-xs-center"
                    >
                      <v-icon
                        v-if="
                          thisProps.item.note && thisProps.item.note === 'WAWA18'
                        "
                        color="teal"
                      >
                        mdi-fraction-one-half
                      </v-icon>
                      <v-icon
                        v-else-if="thisProps.item.qualified === '1' || thisProps.item.qualified === true "
                        color="success"
                      >
                        mdi-check
                      </v-icon>
                      <v-icon
                        v-else-if="thisProps.item.qualified === '0' "
                        color="error"
                      >
                        mdi-close
                      </v-icon>
                    </td>
                    <td
                      v-else
                      class="text-xs-center"
                    >
                      <v-icon color="error">
                        mdi-close
                      </v-icon>
                    </td>
                  </template>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </template>
      </template>
    </v-data-table>
    <template
      v-if="!kpiSept2022"
      #footer
    >
      <td>&nbsp;</td>
      <td
        v-if="kpi2022 & !stopKpi"
        colspan="3"
        class="text-xs-right primary--text"
      >
          &nbsp;
      </td>
      <td
        v-else
        class="text-xs-center primary--text"
      >
        {{ goal }}
      </td>
      <td
        class="text-xs-center primary--text"
      >
        {{ achievement }}
      </td>
    </template>
    <v-alert
      class="mt-0 mb-4"
      :value="true"
      :type="alertType"
    >
      {{ alertText }}
    </v-alert>
    <v-card
      class="transparent"
      flat
    >
      <template v-if="kpi2022">
        <v-parallax
          v-if="annualSubscription.length > 0"
          dark
          height="100"
          src="/img/background.jpg"
        >
          <v-layout
            align-center
            column
            justify-center
          >
            <div class="headline font-weight-light">
              Annual Dealership Subscription
            </div>
            <div class="text-uppercase font-weight-light">
              Fee &mdash; RM {{ kpi2025 ? 500 : 225 }}
            </div>
          </v-layout>
        </v-parallax>
        <template>
          <v-card-text
            v-if="annualSubscription.length > 0"
            class="py-0"
          >
            <v-timeline
              align-top
              dense
            >
              <v-timeline-item
                v-for="(item, index) in annualSubscription"
                :key="index"
                color="amber"
                small
              >
                <v-layout pt-3>
                  <v-flex xs4>
                    <strong>{{ item.title }}</strong>
                  </v-flex>
                  <v-flex>
                    <strong
                      v-if="item.color"
                      :style="item.color"
                    >
                      {{ item.value }}
                    </strong>
                    <strong v-else>{{ item.value }}</strong>
                    <div
                      v-if="item.caption"
                      class="caption"
                      v-html="item.caption"
                    />
                  </v-flex>
                </v-layout>
              </v-timeline-item>
            </v-timeline>
            <v-subheader v-html="footnote" />
          </v-card-text>
        </template>
        <!--v-card-text v-html="kpiDescription" /-->
        <v-parallax
          dark
          height="70"
          src="/img/background.jpg"
        >
          <v-layout
            align-center
            column
            justify-center
          >
            <div class="headline font-weight-light">
              Performance Measurements
            </div>
          </v-layout>
        </v-parallax>
        <v-list
          class="transparent"
          two-line
        >
          <template v-for="(item, index) in descriptions">
            <v-subheader
              v-if="item.header"
              :key="item.header"
              v-html="item.header"
            />

            <v-divider
              v-else-if="item.divider"
              :key="index"
              :inset="item.inset"
            />

            <div
              v-else-if="item.list"
              :key="item.list"
              v-html="item.list"
              style="padding-left: 16px; color: hsla(0, 0%, 100%, 0.7)"
            />

            <v-list-tile
              v-else
              :key="item.title"
              avatar
            >
              <v-list-tile-avatar>
                <v-btn
                  absolute
                  dark
                  fab
                  :ripple="false"
                  color="black"
                >
                  <v-avatar
                    size="50px"
                    :color="item.kpiColor"
                  >
                    <span
                      class="white--text subheader"
                      v-html="item.kpiAchievement"
                    />
                  </v-avatar>
                </v-btn>
              </v-list-tile-avatar>

              <v-list-tile-content>
                <v-list-tile-title
                  style="white-space: normal; font-size: 14px;"
                  v-html="item.title"
                />
                <v-list-tile-sub-title v-html="item.subtitle" />
              </v-list-tile-content>
            </v-list-tile>
          </template>
        </v-list>
      </template>
      <template v-else>
        <v-card-title>Performance Measurements</v-card-title>
        <v-card-text>
          <p>
            <span class="font-weight-bold accent">KPI 1</span>: Must hit monthly
            minimum 2 ONEXOX Prepaid activations with minimum recharge of RM30
            (accumulative within the same calendar month) for each MSISDN; or
          </p>
          <p>
            <span class="font-weight-bold accent">KPI 2</span>: Must hit monthly
            minimum 2 ONEXOX BLACK activations with minimum B39 plan; or
          </p>
          <p>
            <span class="font-weight-bold accent">KPI 3</span>: Must hit monthly
            minimum 2 ONEXOX BLACK B10 activations wih minumum recharge RM20
            (accumulative within the same calendar month) for each MSISDN; or
          </p>
          <p>
            <span class="font-weight-bold accent">KPI 4</span>: Must hit monthly
            1 ONEXOX Prepaid activation with minimum recharge of RM30
            (accumulative within the same calendar month) and 1 ONEXOX BLACK B39
            activation &amp; above; or
          </p>
          <p>
            <span class="font-weight-bold accent">KPI 5</span>: Must hit monthly
            1 ONEXOX Prepaid activation with minimum recharge of RM30
            (accumulative within the same calendar month) and 1 ONEXOX BLACK B10
            activation with minimum recharge of RM20 (accumulative within the
            same calendar month); or
          </p>
          <p>
            <span class="font-weight-bold accent">KPI 6</span>: Must hit monthly
            1 ONEXOX BLACK activation with minimum B39 plan and 1 ONEXOX BLACK
            B10 activation with minimum recharge of RM20 (accumulative within
            the same calendar month)
          </p>
          <p>
            <span class="font-weight-bold accent">KPI 7</span>: Must hit monthly
            E-Recharge sales total amounting to RM500 &amp; above to the
            subscriber.
          </p>
        </v-card-text>
      </template>
    </v-card>
    <v-card
      v-if="!kpi2022 && false"
      class="transparent"
      flat
    >
      <v-card-title>Demotion of Dealership</v-card-title>
      <v-card-text>
        <p>
          <span class="font-weight-bold accent">Demotion 1</span>: Demoted to
          subscriber profile in the <strong>month of 7th</strong>
          if failed to hit monthly KPI within the KPI cycle (6 months
          consecutively)
        </p>
        <p>
          <span class="font-weight-bold accent">Demotion 2</span>: Demoted to
          subscriber profile in the <strong>month of 7th</strong> if failed to
          maintain monthly
          <strong>RM500 sales top up to subscribers every month</strong> within
          the KPI cycle (6 months consecutively) (Effective 1st August, 2020)
        </p>
      </v-card-text>
    </v-card>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      expand: true,
      search: '',
      achievement: 0,
      activeUntil: 'indefinite date',
      alertText: null,
      alertType: 'info',
      allowedMonths: function (month) {
        const ym = month.split('-')
        const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone(
          'Asia/Kuala_Lumpur',
        )
        const fromDate = DateTime.local(2020, 1, 1).setZone(
          'Asia/Kuala_Lumpur',
        )
        const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

        return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
      },
      announcement: null,
      annualSubscription: [],
      goal: 0,
      kpiAchievement: null,
      kpiDescription: '',
      hideActions: true,
      items: [],
      campaign: [],
      maxDate: '',
      menu: false,
      month: null,
      monthOf: null,
      title: 'Key Performance Indicator',
    }
  },
  computed: {
    footnote: function () {
      return this.stopKpi ? '<div>Please fulfill the <strong>Activation</strong> target to be eligible for a waiver.</div>'
        : '<div>Please fulfill either <strong>Activation</strong> or <strong>E-recharge Sales</strong> target to be eligible for a waiver.</div>'
    },
    stopKpiDescriptions: function () {
      const description = [
        {
          header: '<strong>KPI BULANAN (Jumlah Minimum Bulanan)</strong>',
        },
        { header: 'Pengedar akan layak mendapat insentif jika memenuhi syarat seperti di bawah:' },
        {
          list:
              '<strong>Definasi Kelayakan Insentif</strong><br>' +
              '&mdash; Pengedar Prepaid = Perlu membuat Top-up RM30 <br>' +
              '&mdash; Pengedar Black = Perlu membuat pembayaran penuh + SST (Sales and Service Tax)<br>' +
              '&mdash; Pengedar B10 = Perlu membuat pembayaran penuh + SST (Sales and Service Tax) + RM20<br><br>',
        },
        { header: '<strong>KPI TAHUNAN</strong>' },
        {
          list:
              '<strong>Definasi Pengaktifan</strong></br>' +
              '&mdash; Pengaktifan OneXOX Black B10:<ul><li>Perlu minimum top-up RM20 untuk setiap MSISDN yang terkumpul dalam bulan yang sama.</li></ul>' +
              '&mdash; Kempen SIM PERCUMA:<ul><li>Pengaktifan sim percuma akan dikira sebagai 1 pengaktifan dengan pendaftaran beserta tambah nilai minumum RM10 &amp; ke atas.</li></ul>' +
              '&mdash; Pengaktifan:<br><ul><li>Perlu lakukan pengaktifan 40 dan ke atas setahun.</li><li>Semua pengaktifan yang layak untuk mencapai KPI mestilah 1 (SATU) IC untuk setiap 1 (SATU) MSISDN. Bagi pengaktifan MNP pula dikecualikan dari syarat ini.</li></ul><br>',
        },
        {
          list:
              '<strong>NOTA:</strong><br><ol><li>Basic Dealer tidak tertakluk kepada KPI tahunan.</li><li>Single Level Dealer (SLD) tidak tertakluk kepada KPI tahunan.</li></ol>',
        },
      ]
      return description
    },
    kpi2025Descriptions: function () {
      const description = [
        {
          header: '<strong>SYARAT KPI BULANAN PENGEDAR</strong>',
        },
        { header: 'Pengedar akan layak mendapat insentif jika memenuhi syarat seperti di bawah:' },
        {
          list:
              '<strong>Definisi Pengaktifan</strong><br>' +
              '&mdash;Untuk pelan ONEXOX Black: Hanya pengaktifan pelan minima B35 & ke atas akan dikira sebagai 1 pengaktifan (dikira berdasarkan pelan terkini).<br>' +
              '&mdash; Untuk pelan ONEXOX Prepaid: Hanya pengaktifan pelan minima 1X-35 & ke atas di dalam bulan yang sama Sahaja akan dikira sebagai 1 pengaktifan (dikira berdasarkan langganan pelan pertama Sahaja).<br>' +
              '&mdash; Semua pengaktifan yang layak untuk mencapai KPI mestilah 1 (SATU) IC untuk setiap 1 (SATU) MSISDN. (Pengaktifan MNP dikecualikan).<br>' +
              '&mdash; Hanya pengaktifan baharu akan dikira - COP, COBP dan nombor “reinstate” tidak akan dianggap sebagai 1 pengaktifan.<br>' +
              '&mdash; Semua pengaktifan yang layak mencapai KPI mestilah dalam status AKTIF sehingga akhir bulan.<br>' +
              '&mdash; Pengaktifan MNP akan dikira berdasarkan tarikh berjaya (Activated) sahaja.<br><br>',
        },
        { header: '<strong>Syarat Kelayakan Insentif</strong>' },
        {
          list:
              '<strong>Definasi Pengaktifan</strong></br>' +
              '&mdash; Mesti mencapai sekurang-kurangnya 1 (SATU) kategori KPI untuk layak menerima Pembayaran.<br>' +
              '&mdash;Hanya nombor DREG yang berstatus AKTIF sahaja layak menerima pembayaran (BARRED dan BLOCKED tidak layak).<br>' +
              '&mdash;Pengedar perlu memastikan talian DREG anda berada dalam status Aktif sebelum akhir bulan.</ul><br><br>',
        },
        {
          list:
              '<strong>Syarat Pembayaran Hibah</strong></br>' +
              '&mdash; Bayaran Hibah akan dibayar setiap 15 haribulan (hari bekerja sahaja).<br>' +
              '&mdash; Peniaga yang mempunyai Hibah kurang dari RM500 termasuk semua pengedar “Basic Dealer”: Layak untuk E-Recharge sahaja.<br>' +
              '&mdash; Peniaga yang mempunyai Hibah melebihi RM500: boleh memilih sama ada Tunai ATAU E-Recharge.Pilihan ini perlu dibuat sebelum akhir bulan.<br>' +
              '&mdash; Pengedar Mysiswa (ID MYSD): Boleh memilih untuk mendapatkan Hibah Tunai jika melebihi RM200.<br>' +
              '&mdash; Yuran pengurusan RM3 akan dikenakan jika memilih kaedah pembayaran melalui Tunai.</ul><br><br>',
        },
        {
          list:
              '<strong>NOTA:</strong><br><ol><li>Basic Dealer tidak tertakluk kepada KPI tahunan.</li><li>Single Level Dealer (SLD) tidak tertakluk kepada KPI tahunan.</li></ol>',
        },
      ]
      return description
    },
    descriptions: function () {
      if (this.kpi2025) {
        return this.kpi2025Descriptions
      } else if (this.stopKpi) {
        return this.stopKpiDescriptions
      }
      let description = [
        {
          header:
            'Semua pengedar sedia ada (tidak termasuk Basic Dealer) adalah tertakluk kepada 3 pilihan KPI bulanan yang dinyatakan di bawah.',
        },
        {
          kpiColor: 'grey',
          kpiAchievement: 'KPI 01',
          title:
            '2 pengaktifan <span class="grey--text text--lighten-1"></span> ATAU RM500 jualan eRecharge <span class="grey--text text--lighten-1">(hibah eRecharge)</span>',
          subtitle: '&mdash; Layak insentif 5%, 1%, 1%, 0.5%',
        },
        { divider: true, inset: true },
        {
          kpiColor: 'orange darken-3',
          kpiAchievement: 'KPI 02',
          title:
            '5 pengaktifan <span class="grey--text text--lighten-1">(hibah tunai)</span>',
          subtitle: '&mdash; Layak insentif 10%, 2%, 2%, 1%',
        },
        { divider: true, inset: true },
        {
          kpiColor: '#800000',
          kpiAchievement: 'KPI 03',
          title:
            '20 pengaktifan <span class="grey--text text--lighten-1">(hibah tunai)</span>',
          subtitle: '&mdash; Layak insentif 10%, 4%, 4%, 2%',
        },
        { header: 'Semua pengedar baharu diberi 3 bulan pengecualian KPI.' },
      ]
      if (this.kpiSept2022) {
        description = [
          {
            header:
              'Semua pengedar sedia ada (tidak termasuk Basic Dealer) adalah tertakluk kepada 5 pilihan KPI bulanan yang dinyatakan berikut.',
          },
          {
            kpiColor: 'grey',
            kpiAchievement: 'KPI 01A',
            title: '1 pengaktifan ATAU RM500 jualan eRecharge',
            subtitle: '&mdash; Kelayakan insentif 6% (eRecharge Tier 1)',
          },
          { divider: true, inset: true },
          {
            kpiColor: 'grey darken-2',
            kpiAchievement: 'KPI 01B',
            title: '1 pengaktifan DAN RM500 jualan eRecharge',
            subtitle: '&mdash; Kelayakan insentif 10% (tunai Tier 1)',
          },
          { divider: true, inset: true },
          {
            kpiColor: 'grey darken-3',
            kpiAchievement: 'KPI 01C',
            title: '2 pengaktifan',
            subtitle: '&mdash; Kelayakan insentif 10% (tunai Tier 1)',
          },
          { divider: true, inset: true },
          {
            kpiColor: 'orange darken-3',
            kpiAchievement: 'KPI 02',
            title: '2 pengaktifan DAN RM500 jualan eRecharge',
            subtitle: '&mdash; Kelayakan insentif 10%, 2%, 2%, 1% (tunai)',
          },
          { divider: true, inset: true },
          {
            kpiColor: '#800000',
            kpiAchievement: 'KPI 03',
            title:
              '50 pengaktifan (Min 20 pengaktifan B39 dan keatas dan 30 prepaid) + 5 perlantikan pengedar + RM2000 jualan eRecharge',
            subtitle: '&mdash; Kelayakan insentif 10%, 4%, 4% 2% (tunai)',
          },
          {
            list: '<br><strong>DEFINASI PENGAKTIFAN</strong><br>&mdash; 1 Prepaid WAWA18 = 1 KPI <br>&mdash; 1 Prepaid + top up RM30 = 1 KPI<br>&mdash; 1 Black B39FC = 1 KPI<br>&mdash; 1 Black B10 + top up RM20 = 1 KPI<br>&mdash; 1 IC for 1 MSISDN activation per month',
          },
          { header: 'Semua pengedar baharu diberi 3 bulan pengecualian KPI.' },
          {
            header:
              'Berkuatkuasa 1 September 2022, 1 pengaktifan WAWA18 dikira sebagai 1 KPI.',
          },
          {
            header:
              'Basic Dealer dikecualikan KPI sehingga mencapai 100 pengaktifan sebelum dinaik taraf secara automatik ke Full Dealer.',
          },
          { header: 'Basic Dealer tidak layak melantik pengedar baharu.' },
        ]
      }

      return description
    },
    stopKpi: function () {
      if (!this.monthOf) return false

      const ym = this.monthOf.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone(
        'Asia/Kuala_Lumpur',
      )
      const fromDate = DateTime.local(2024, 4, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    kpi2022: function () {
      if (!this.monthOf) return false

      const ym = this.monthOf.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone(
        'Asia/Kuala_Lumpur',
      )
      const fromDate = DateTime.local(2022, 3, 1).setZone('Asia/Kuala_Lumpur')
      // const toDate = DateTime.local(2024, 3, 31).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    kpi2025: function () {
      if (!this.monthOf) return false

      const ym = this.monthOf.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone(
        'Asia/Kuala_Lumpur',
      )
      const fromDate = DateTime.local(2025, 4, 1).setZone('Asia/Kuala_Lumpur')
      // const toDate = DateTime.local(2024, 3, 31).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    kpiSept2022: function () {
      if (!this.monthOf) return false

      const ym = this.monthOf.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone(
        'Asia/Kuala_Lumpur',
      )
      const fromDate = DateTime.local(2022, 9, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    kpiAct2024: function () {
      if (!this.monthOf) return false

      const ym = this.monthOf.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone(
        'Asia/Kuala_Lumpur',
      )
      const fromDate = DateTime.local(2024, 10, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    headers: function () {
      if (this.kpi2025) {
        return [
          {
            text: 'Indicator',
            align: 'left',
            sortable: false,
            value: 'indicator',
          },
          {
            text: 'KPI01 Goal',
            align: 'center',
            sortable: false,
            value: 'kpi01goal25',
          },
          {
            text: 'KPI02 Goal',
            align: 'center',
            sortable: false,
            value: 'kpi02goal25',
          },
          {
            text: 'KPI03 Goal',
            align: 'center',
            sortable: false,
            value: 'kpi03goal25',
          },
          {
            text: 'KPI04 Goal',
            align: 'center',
            sortable: false,
            value: 'kpi04goal25',
          },
          {
            text: 'KPI05 Goal',
            align: 'center',
            sortable: false,
            value: 'kpi05goal25',
          },
          {
            text: 'KPI06 Goal',
            align: 'center',
            sortable: false,
            value: 'kpi06goal25',
          },
          {
            text: 'KPI07 Goal',
            align: 'center',
            sortable: false,
            value: 'kpi07goal25',
          },
          {
            text: 'Achievement',
            align: 'center',
            sortable: false,
            value: 'achievement',
          },
        ]
      } else if (this.stopKpi) {
        return [
          {
            text: 'Indicator',
            align: 'left',
            sortable: false,
            value: 'campaign',
          },
          {
            text: 'Goal',
            align: 'center',
            sortable: false,
            value: 'goal',
          },
          {
            text: 'Achievement',
            align: 'center',
            sortable: false,
            value: 'achievement',
          },
        ]
      } else if (this.kpi2022 && this.monthOf) {
        if (this.kpiSept2022) {
          return [
            {
              text: 'Indicator',
              align: 'left',
              sortable: false,
              value: 'indicator',
            },
            {
              text: 'KPI 01A Goal',
              align: 'center',
              sortable: false,
              value: 'kpi01Agoal',
            },
            {
              text: 'KPI 01B Goal',
              align: 'center',
              sortable: false,
              value: 'kpi01Bgoal',
            },
            {
              text: 'KPI 01C Goal',
              align: 'center',
              sortable: false,
              value: 'kpi01Cgoal',
            },
            {
              text: 'KPI 02 Goal',
              align: 'center',
              sortable: false,
              value: 'kpi02goal',
            },
            {
              text: 'KPI 03 Goal',
              align: 'center',
              sortable: false,
              value: 'kpi03goal',
            },
            {
              text: 'Achievement',
              align: 'center',
              sortable: false,
              value: 'achievement',
            },
          ]
        } else {
          return [
            {
              text: 'Indicator',
              align: 'left',
              sortable: false,
              value: 'indicator',
            },
            {
              text: 'KPI01 Goal',
              align: 'center',
              sortable: false,
              value: 'kpi01goal',
            },
            {
              text: 'KPI02 Goal',
              align: 'center',
              sortable: false,
              value: 'kpi02goal',
            },
            {
              text: 'KPI03 Goal',
              align: 'center',
              sortable: false,
              value: 'kpi03goal',
            },
            {
              text: 'Achievement',
              align: 'center',
              sortable: false,
              value: 'achievement',
            },
          ]
        }
      } else {
        return []
      }
    },
    kpiColor: function () {
      if (this.kpiAchievement === 'KPI 01' || this.kpiAchievement === 'KPI 01A') { return 'grey' } else if (this.kpiAchievement === 'KPI 02') return 'grey darken-2'
      else if (this.kpiAchievement === 'KPI 03') return '#800000'
      else if (this.kpiAchievement === 'KPI 04') return '#4caf50'
      else if (this.kpiAchievement === 'KPI 05') return '#3f51b5'
      else if (this.kpiAchievement === 'KPI 06') return '#ffeb3b'
      else if (this.kpiAchievement === 'KPI 07') return '#9c27b0'
      else if (this.kpiAchievement === 'KPI 01B') return 'grey darken-2'
      else if (this.kpiAchievement === 'KPI 01C') return 'grey darken-3'
      else return 'transparent'
    },
  },
  watch: {
    monthOf: function (val) {
      this.items = []
      this.campaign = []
      this.getKpi(val)
      // this.getKpiDescription()
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.month = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    customFilter (items, search) {
      return items.filter(
        (activation) =>
          JSON.stringify(activation)
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1,
      )
    },
    getKpiDescription: function () {
      this.$rest
        .get('getKpiDescription.php', createGetParams({}))
        .then(
          function (response) {
            this.kpiDescription = response.data.kpiDescription
          }.bind(this),
        )
        .catch((error) => {
          console.log(error)
        })
    },
    getKpi: function (month) {
      const params = createGetParams({
        month: month,
      })
      this.$rest
        .get('getKpi.php', params)
        .then(
          function (response) {
            let count = 0
            this.items = response.data.kpi
            this.campaign = response.data.campaign
            this.goal = response.data.goal
            this.kpiAchievement = response.data.kpiAchievement
            this.achievement = response.data.achievement
            this.idleMonth = response.data.idleMonth
            this.announcement = response.data.announcement
              ? response.data.announcement
              : null
            if (response.data.annualSubscription) {
              this.annualSubscription = response.data.annualSubscription
            }
            if (response.data.activeUntil) {
              this.activeUntil = response.data.activeUntil
            }
            this.items.forEach((item) => {
              count += item.items.length
            })
            this.hideActions = count < 25
            if (this.achievement >= this.goal) {
              this.alertText =
                'Congratulations! You have achieved your KPI this month.'
              this.alertType = 'success'
            } else if (this.idleMonth >= 6) {
              this.alertText =
                'You have been idle for ' +
                this.idleMonth +
                ' months. Your dealer account will be terminated if you stay idle longer!'
              this.alertType = 'error'
            } else if (this.idleMonth < 0) {
              this.alertText =
                'You are not obliged to fulfill your KPI this month.'
              this.alertType = 'info'
            } else {
              let unfulfilled = 'You have not fulfilled your KPI this month. '
              if (this.idleMonth > 0 && this.idleMonth < 6) {
                unfulfilled +=
                  'You have until ' +
                  this.activeUntil +
                  ' to fulfill your dealership T&C before being demoted!'
              }
              this.alertText = unfulfilled
              this.alertType = 'warning'
            }
          }.bind(this),
        )
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
